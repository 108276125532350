<template>
  <Layout>
    <PageHeader :title="title" :items="items"> </PageHeader>
    <div class="row" id="page">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mb-0">
              <b-table
                v-if="tableData?.response"
                striped
                bordered
                :items="tableData.response"
                :fields="fields"
                :sort-by.sync="sortBy"
                :per-page="0"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
              >
                <template v-slot:cell(view)="row">
                  <b-button
                    variant="success"
                    class="btn-sm"
                    v-b-modal="'questionModal'"
                    @click="sendInfoNote(row.item.id)"
                    >View</b-button
                  >
                </template>
              </b-table>
              <div
                class="d-flex justify-content-center my-5"
                v-if="tableData?.response?.length == 0"
              >
                <div style="display: grid">
                  <img src="../../../../public/noData.svg" alt="" />
                  <span class="no_content_text text-center"
                    >No Data Available</span
                  >
                </div>
              </div>
            </div>
            <div class="data_paginate" v-if="tableData.total_pages > 1">
              <div
                class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <b-pagination
                    v-model="currentPage"
                    first-number
                    last-number
                    :total-rows="tableData.total"
                    :total-pages="tableData.total_pages"
                    :per-page="tableData.per_page"
                  ></b-pagination>
                </ul>
              </div>
              <div class="form-inline navbar-search">
                <span class="mr-2">Go to a Particular Paginated Page :</span>
                <div class="input-group">
                  <b-form-input
                    id="case"
                    v-model="pageNo"
                    placeholder="Enter Page No"
                    class="in-width"
                  >
                  </b-form-input>
                  <div class="input-group-append">
                    <button class="btn btn-search" @click="filterPage">
                      <i class="fas fa-search fa-sm"> </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="questionModal"
      title="View Question and Answer"
      no-close-on-backdrop
      centered
      hide-header-close
      cancel-title="Close"
      size="lg"
    >
      <div class="table-responsive mb-0">
        <b-table
          striped
          bordered
          :items="viewReportData"
          :fields="report_fields"
          :sort-by.sync="sortBy"
          :per-page="0"
          :current-page="currentPage"
          :sort-desc.sync="sortDesc"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          responsive="sm"
          class="table-bordered table-hover"
          :key="key"
        >
          <template v-slot:cell(question)="row">
            <span>{{ row.item.question.question }}</span>
          </template>
          <template v-slot:cell(answer)="row">
            <span>{{ row.item.answer }}</span>
          </template>
        </b-table>
      </div>
      <div
        class="d-flex justify-content-center my-5"
        v-if="viewReportData?.length == 0"
      >
        <div style="display: grid">
          <img src="../../../../public/noData.svg" alt="" />
          <span class="no_content_text text-center">No Data Found</span>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import pageMixin from "../../../mixins/ModuleJs/report-survey";

export default {
  mixins: [MixinRequest, pageMixin],
  data() {
    return {
      title: "Report Survey",
      items: [
        {
          text: "Back",
          href: "/survey",
        },

        {
          text: "List",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  created() {
    this.fetchData();
  },
};
</script>
