import { survey } from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      viewReportData: [],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "fname",
          label: "First Name",
        },
        {
          key: "lname",
          label: "Last Name",
        },
        {
          key: "mobile_number",
          label: "Mobile Number",
        },
        {
          key: "view",
        },
      ],
      report_fields: [
        {
          key: "question",
        },
        {
          key: "answer",
        },
      ],
      params: "",
      filter: null,
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filterOn: [],
      key: 0,
      currentPage: 1,
      activeTab: "all",
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) this.fetchData("search");
      else if (this.filter.length == 0) this.fetchData("search");
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData("search");
        }
      } else if (this.filter.length == 0) this.fetchData("search");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(pagination = null) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${survey.reportSurvey}/${this.$route.params.id}`;
        if (pagination == "search") {
          url = survey.getSurvey + "?search=" + this.filter;
        } else if (pagination) {
          url = url + "?page=" + pagination;
        }
        const data = await this.getRequest(url);
        if (data.status) {
          this.tableData = data;
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async sendInfoNote(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${survey.viewReport}/${id}?survey_id=${this.$route.params.id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          this.viewReportData = data.response;
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `page=${value}`;
        this.fetchData(value);
        this.pageNo = this.currentPage;
      },
    },
  },
};
